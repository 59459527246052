::selection {
  background-color: #69054c;
  color: #fff;
}

.Quote-cards {
    position: relative;  
    list-style-type: none;
    padding: 0;
    max-width: 50em;
    width: 100%;
    margin: 20% auto 0;
  }
  

  .Quote-card {
    transition: ease-in-out 1s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    
    background: #ccc;
    border-radius: 30px;
    padding: 40px;
    box-shadow: 0 0 40px #fff;
    /* transform: translateY(0) rotate(4deg) translateX(25px) scale(1); */
    transform-origin: 0 0;
    
    transition: transform 0.6s cubic-bezier(.8,.2,.1,0.8) 0.1s,
      background 0.4s linear;
    
    cursor: pointer;

    /* user-select: none; */
    /* overflow: scroll; */
    
    /* :last-child { margin-bottom: 0; } */
  }
  
  .Quote-card--next { 
    z-index: 5; 
    transform: translateY(-25px) rotate(4deg) translateX(25px) scale(1);
  }
  
  .Quote-card--out {
    transition: ease-in-out 1s;
    animation: card-out 0.6s cubic-bezier(.8,.2,.1,0.8);
    transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95);
    z-index: 1;
    background: #bbb;
  }
  
  @keyframes card-out {
    0% { z-index: 20; transform: translateY(0px) rotate(-4deg); }
    50% { transform: translateY(-120%) rotate(-5deg) translateX(-40px); }
    80% { z-index: 1; }
    100% { transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95); }
  }
  
  .Quote-card--current {
    transition: ease-in-out 1s;
    cursor: auto;
    user-select: auto;
    position: relative;
    z-index: 10;
    opacity: 1;
    background: #EEE;
    transform: rotate(-1deg) translateX(0%) scale(1); 
  }
  
.wish-para {
  white-space: pre-wrap;
  font-family: "Single Day";
  color: #333;
}

  .Quote-body {
    transition: ease-in-out 1s;
    height: 100vh; 
    padding: 40px; 
    /* padding-bottom: 1000px; */
    /* background: #222232;  */
    background-image: url("./Images/Bday2.png");
    background-size: 30%;
    background-repeat: repeat-x repeat-y;
    overflow: scroll;

    /* background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url('../../Images/singlepost-light.jpg'); */
    /* background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center; */
  }
  
  .Quote-h1 { margin: 0; }
