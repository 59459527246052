

* {
    transition: all 0.2s ease-in-out;
  }
  
  .App {
    background: #008888;
    /* background: #ffa6ff; */
    display: grid;
    place-items: center;
    height: 100vh;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  .card {
    background: #fdfdfd;
    border-radius: 4px;
    height: 85vh;
    width: 80vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    overflow: hidden;
  }
  
  
  @media only screen and (min-width: 1000px) {
    .card {
      flex-direction: row-reverse;
    }
    .card img.birthday {
      width: 100%;
      max-width: 50vw;
      max-height: unset;
    }
  }
  
  @media only screen and (max-height: 640px) {
    .card {
      flex-direction: row-reverse;
    }
    .card img.birthday {
      width: 100%;
      max-width: 50vw;
      max-height: unset;
    }
  }
  
  img.birthday {
    max-height: 40vh;
  }
  
  .text {
    padding: 1em;
  }
  
  .muted {
    opacity: 0.4;
  }
  
  .space {
    height: 100px;
  }